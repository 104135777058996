import "./kitcintent.scss";
import Fade from "react-reveal/Fade";
const KitcontentOutcomes = () => {
  return (
    <div className="outcomes">
      <h2 className="outcomes__head">EACH KIT COMES WITH</h2>
      <div className="outcomes__holder">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                Learning <br></br>Projects
              </h5>
              <img
                src="/Images/LandingPage/Kitcontent/paper-ship.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>A buildable project (check out the list below)</li>
              <li>Helps to demonstrate his or her capabilities</li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top">
              <h5>
                Clue-Up<br></br>Game
              </h5>
              <img
                src="/Images/LandingPage/Kitcontent/start-up.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                A card game called 'Clue-Up' that improves awareness about
                various topics such as Countries around the world, famous
                personalities, Space science, Inventions and Discoveries etc.
              </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                Activity<br></br>Sheets
              </h5>
              <img
                src="/Images/LandingPage/Kitcontent/abacus.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>A bunch of activity sheets to practice.</li>
              <li>
                Provide the context and direction and importantly the process to
                participate in activities
              </li>
            </ul>
          </div>
        </Fade>
      </div>
      <div className="outcomes__holder outcomes__holder3 ">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top ">
              <h5>
                Hand-out<br></br>Snippets
              </h5>
              <img
                src="/Images/LandingPage/Kitcontent/books.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                A set of hand-outs that will comprise of short snippets to read,
                content about general awareness, speech and grammar, factfiles
                etc.
              </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card outcomes__holder__card3">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                Conceptual<br></br>Videos
              </h5>
              <img
                src="/Images/LandingPage/Kitcontent/youtube.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                Supporting videos explaining various concepts provided in the
                kit.Which helps to Learn with amazing visuals.
              </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card ">
            <div className="outcomes__holder__card__top ">
              <h5>
                Webinars<br></br>Online Meets
              </h5>
              <img
                src="/Images/LandingPage/Kitcontent/paper.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                Webinars and online meets for group discussions, doubt
                clarification and debates in order to inculcate confidence,
                positive group dynamics and social skills.
              </li>
            </ul>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default KitcontentOutcomes;
