import "./header.scss";
import Fade from "react-reveal/Fade";
const Careerheader = () => {
  return (
    <div className="career-header">
      <Fade left>
        <h2 className="pageheader__head">CAREER</h2>
      </Fade>
      <Fade left delay={1000}>
        <p className="career-header__text">
          Career opportunities from Beylabee
        </p>
      </Fade>
    </div>
  );
};

export default Careerheader;
