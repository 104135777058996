import "./kitcontent.scss";
//import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
const Kitcontent = () => {
  const history = useHistory();
  return (
    <div className="kitcontent">
      <Fade>
        <h2 className="head">Fueling Your Curiosity</h2>

        <p className="kitcontent__h2"> Beylabee Offers </p>
      </Fade>
      <div className="kitcontent__holder">
        <Fade cascade bottom>
          <div className="kitcontent__holder__holder1">
            <div className="kitcontent__holder__card">
              <img
                src="/Images/LandingPage/kitcontentImages/im-4.png"
                alt="Kit Content"
                className="kitcontent__holder__card__img"
              />
              <p className="kitcontent__holder__card__text">
                Hands-on education
              </p>
            </div>
            <div className="kitcontent__holder__card">
              <img
                src="/Images/LandingPage/kitcontentImages/img-2.png"
                alt="Kit Content"
                className="kitcontent__holder__card__img"
              />
              <p className="kitcontent__holder__card__text">
                Developing life skills
              </p>
            </div>
            <div className="kitcontent__holder__card cardExtended">
              <img
                src="/Images/LandingPage/kitcontentImages/img-3.png"
                alt="Kit Content"
                className="kitcontent__holder__card__img"
              />
              <p className="kitcontent__holder__card__text">
                Coding & verbal proficiency
              </p>
            </div>
          </div>
        </Fade>
        <Fade cascade bottom>
          <div className="kitcontent__holder__holder1 kitcontent__holder__holder2">
            <div className="kitcontent__holder__card">
              <img
                src="/Images/LandingPage/kitcontentImages/img-1.png"
                alt="Kit Content"
                className="kitcontent__holder__card__img"
              />
              <p className="kitcontent__holder__card__text">
                Reasoning & logic development
              </p>
            </div>
            <div className="kitcontent__holder__card">
              <img
                src="/Images/LandingPage/kitcontentImages/img.png"
                alt="Kit Content"
                className="kitcontent__holder__card__img"
              />
              <p className="kitcontent__holder__card__text">
                Problem solving & social skills
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <Fade>
        <Link to="contact" className="navbtnholder" duration={1000}>
          <button className="header-btn kitcontent__btn">
            <p>ENROLL NOW</p>
          </button>
        </Link>
      </Fade>
      <div className="kitcontent__btm">
        <img
          effect="blur"
          src="/Images/LandingPage/kitcontentImages/kitToys.png"
          alt="Boy holding Flag"
          className="kitcontent__img1"
        />
        <img
          effect="blur"
          src="/Images/LandingPage/kitcontentImages/kit.png"
          alt="Boy holding Flag"
          className="kitcontent__img1 kitcontent__img2"
        />
      </div>
    </div>
  );
};

export default Kitcontent;
