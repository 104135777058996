import { BrowserRouter, Switch, Route } from "react-router-dom";
import KitcontentPage from "./LandingPage/Pages/kitcontents";
import BrilliantBee from "./LandingPage/Pages/brilliantbee";
import Builderbee from "./LandingPage/Pages/builderbee";
import Career from "./LandingPage/Pages/career";
import Coderbee from "./LandingPage/Pages/Coderbee";
import Home from "./LandingPage/Pages/home";
import Linguistbee from "./LandingPage/Pages/liguistbee";
import AllCoursePage from "./LandingPage/Pages/Allcoursespage";
import Bibliobee from "./LandingPage/Pages/bibliobee";
import Scholarbee from "./LandingPage/Pages/scholarbee";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
        <Switch>
          <Route exact path="/brilliantbee" component={BrilliantBee} />
        </Switch>
        <Switch>
          <Route exact path="/linguistbee" component={Linguistbee} />
        </Switch>
        <Switch>
          <Route exact path="/builderbee" component={Builderbee} />
        </Switch>
        <Switch>
          <Route exact path="/coderbee" component={Coderbee} />
        </Switch>
        <Switch>
          <Route exact path="/bibliobee" component={Bibliobee} />
        </Switch>
        <Switch>
          <Route exact path="/career" component={Career} />
        </Switch>
        <Switch>
          <Route exact path="/scholarbee" component={Scholarbee} />
        </Switch>
        <Switch>
          <Route exact path="/kitcontent" component={KitcontentPage} />
        </Switch>
        <Switch>
          <Route exact path="/courses" component={AllCoursePage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
