import Navbar from "../Nav/nav";

import Footer from "../footer/footer";
import styled from "styled-components";
import Builderbeepage from "../Builderbee/pageheader";
import BuilderbeeOutcomes from "../Builderbee/builderbeeoutcome";
import SuperContact from "../universal-contact/super-contact";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
const Builderbee = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />
      <Builderbeepage />
      <Section>
        <BuilderbeeOutcomes />
        <SuperContact type={"Builderbee"} />
      </Section>
      <Footer />
    </>
  );
};

export default Builderbee;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
