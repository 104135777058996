import "./courses.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "react-bootstrap";
import "./carousel.css";

const Courses = () => {
  return (
    <div className="courses">
      <Carousel interval={2000}>
        <Carousel.Item>
          <div>
            <div className="courses__card course1">
              <h2>Builder Bee</h2>
              <p className="courses__card__text">
                Builder Bee: This is an initiative to inculcate a hands-on
                learning culture as opposed to a largely theoretical system by
                means of a monthly subscription model of project-based learning
                kits that help increase long-term retention, enhances
                problem-solving skills and improves students' attitude towards
                learning. The kits are easy to understand and aims to fuel a
                child's curiosity so they learn to see beyond just books.
                <br></br>&nbsp;
              </p>
              <button
                className="courses__card__btn"
                onClick={() => {
                  window.location.href = "/builderbee#";
                }}
              >
                KNOW MORE
              </button>
              <picture>
                <source
                  srcset="/Images/LandingPage/images/course2.webp"
                  type="image/webp"
                />
                <img
                  src="/Images/LandingPage/images/course2.jpg"
                  alt="Course1"
                  className="courses__card__img"
                />
              </picture>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div>
            <div className="courses__card course2">
              <h2>Coder Bee</h2>
              <p className="courses__card__text">
                A curriculum introducing the programming languages ‘Python’ and
                ‘JavaScript’, built to equip students with skills that will help
                them keep in pace with the booming Tech World where coding has
                now become the universal language. Coding helps develop the
                analytical side of the brain, be creative, build solutions and
                solve problems more systematically. Learning programming at a
                young age opens a lifetime of opportunities.<br></br>&nbsp;
              </p>
              <button
                className="courses__card__btn"
                onClick={() => {
                  window.location.href = "/coderbee#";
                }}
              >
                KNOW MORE
              </button>
              <picture>
                <source
                  srcset="/Images/LandingPage/images/course1.webp"
                  type="image/webp"
                />
                <img
                  src="/Images/LandingPage/images/course1.jpg"
                  alt="Course2"
                  className="courses__card__img"
                />
              </picture>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div>
            <div className="courses__card course3">
              <h2>Brilliant Bee</h2>
              <p className="courses__card__text">
                This is a course that deals with topics such as quantitative
                aptitude, logical and mathematical reasoning, verbal proficiency
                and comprehension etc. in order to build a strong foundation for
                students’ rational and critical thinking skills that will be
                highly useful in preparing for and attempting various
                competitive exams such as bank and railway exams, UPSC prelims,
                GATE, UGC, Management exams etc. in the future.<br></br>&nbsp;
              </p>
              <button
                className="courses__card__btn"
                onClick={() => {
                  window.location.href = "/brilliantbee#";
                }}
              >
                KNOW MORE
              </button>
              <picture>
                <source
                  srcset="/Images/LandingPage/images/course3.webp"
                  type="image/webp"
                />
                <img
                  src="/Images/LandingPage/images/course3.jpg"
                  alt="Course2"
                  className="courses__card__img"
                />
              </picture>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div>
            <div className="courses__card course4">
              <h2>Linguist Bee</h2>
              <p className="courses__card__text">
                This programme is formulated with the aim to help develop a keen
                interest among students to learn different languages. People who
                know more languages have better memory, improved concentration,
                superior multitasking abilities, better critical thinking and
                finer listening skills. Language skills can remarkably set them
                apart and give them better possibilities to interact with varied
                people in both their personal and professional lives in the
                future.<br></br>&nbsp;
              </p>
              <button
                className="courses__card__btn"
                onClick={() => {
                  window.location.href = "/linguistbee#";
                }}
              >
                KNOW MORE
              </button>
              <picture>
                <source
                  srcset="/Images/LandingPage/images/course4.webp"
                  type="image/webp"
                />
                <img
                  src="/Images/LandingPage/images/course4.jpg"
                  alt="Course2"
                  className="courses__card__img"
                />
              </picture>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div>
            <div className="courses__card course4">
              <h2>BiblioBee</h2>
              <p className="courses__card__text">
                This program is carefully designed in order to introduce and
                instill a reading habit in students from a young age. (It is a
                known fact that reading helps strengthen the brain, build
                vocabulary, increase creativity and empathy and also prevents
                cognitive decline.) When reading habit is cultivated from a
                young age it stimulates curiosity in a child, develops the
                child's communication skills, increases their concentration,
                improves the attention span and aids memory retention.
              </p>
              <button
                className="courses__card__btn"
                onClick={() => {
                  window.location.href = "/bibliobee#";
                }}
              >
                KNOW MORE
              </button>
              <picture>
                <source
                  srcset="/Images/LandingPage/images/course5.webp"
                  type="image/webp"
                />
                <img
                  src="/Images/LandingPage/images/course5.jpg"
                  alt="Course2"
                  className="courses__card__img"
                />
              </picture>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div>
            <div className="courses__card course4">
              <h2>ScholarBee</h2>
              <p className="courses__card__text">
                This program is aimed at teaching students school-level Maths
                and Science in order to help them understand the concepts better
                and and know the importance of the subject in real life. We
                provide personalized one-on-one live classes to ensure proper
                understanding of the subjects, active doubt clarification and
                individual attention. Students will test their understanding via
                our weekly tests.<br></br>&nbsp;
              </p>
              <button
                className="courses__card__btn"
                onClick={() => {
                  window.location.href = "/scholarbee#";
                }}
              >
                KNOW MORE
              </button>
              <picture>
                <source
                  srcset="/Images/LandingPage/images/course6.webp"
                  type="image/webp"
                />
                <img
                  src="/Images/LandingPage/images/course6.jpg"
                  alt="Course2"
                  className="courses__card__img"
                />
              </picture>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Courses;
