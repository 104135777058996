import Fade from "react-reveal/Fade";
import "./linguistbee.scss";
import { Link } from "react-scroll";
const Linguistbeepage = () => {
  return (
    <div className="pageheader pageheader2">
      <div className="pageheader__col1">
        <Fade left>
          <h2 className="pageheader__head">LINGUIST BEE</h2>
        </Fade>
        <Fade left delay={1000}>
          <p className="pageheader__text">
            This programme is designed to develop a keen interest among students
            to learn different languages and explore varied cultures. It is a
            known fact that people who know more languages have better memory,
            improved concentration, superior multitasking abilities, better
            critical thinking and finer listening skills. Language skills can be
            a remarkable advantage that sets them apart and gives them better
            possibilities to interact with varied people in both their personal
            and professional lives in the future.
          </p>
        </Fade>
        <Fade bottom delay={1000}>
          <Link to="contact">
            <button className="header-btn pageheader__btn">
              <p>BOOK FREE DEMO CLASS</p>
            </button>
          </Link>
        </Fade>
      </div>
      <div className="pageheader__col2">
        <Fade bottom delay={1500}>
          <div className="pageheader__card">
            <p className="pageheader__card__text">
              For classes <span>6-10</span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Fees
              <span>
                11,000<span className="pageheader__card__text2">+GST</span>
              </span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Total class
              <span>
                40<span className="pageheader__card__text2">hours</span>
              </span>
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Linguistbeepage;
