import "./builderbee.scss";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
const BuilderbeeOutcomes = () => {
  return (
    <>
      <div className="outcomes ">
        <h2 className="outcomes__head">EACH KIT COMES WITH</h2>
        <div className="outcomes__holder">
          <Fade bottom>
            <div className="outcomes__holder__card">
              <div className="outcomes__holder__card__top outcomes__holder__card__top2">
                <h5>
                  Projects <br />
                  and Games
                </h5>
                <img
                  src="/Images/LandingPage/brilliantBee/group-theory.png"
                  alt="Brilliant bee features"
                  className="outcomes__holder__card__top__img"
                />
              </div>
              <ul className="outcomes__holder__card__list">
                <li>A buildable project</li>
                <li>
                  A card game 'Clue-Up' that improves awareness about various
                  topics
                </li>
              </ul>
            </div>
          </Fade>
          <Fade bottom>
            <div className="outcomes__holder__card">
              <div className="outcomes__holder__card__top">
                <h5>
                  Activity Sheets <br />
                  and Magazines
                </h5>
                <img
                  src="/Images/LandingPage/brilliantBee/puzzle-piece.png"
                  alt="Brilliant bee features"
                  className="outcomes__holder__card__top__img"
                />
              </div>
              <ul className="outcomes__holder__card__list">
                <li>A bunch of activity sheets to practice.</li>
                <li>A monthly kid’s magazine</li>
              </ul>
            </div>
          </Fade>
          <Fade bottom>
            <div className="outcomes__holder__card">
              <div className="outcomes__holder__card__top outcomes__holder__card__top2">
                <h5>
                  Videos<br></br>
                  and Webinars
                </h5>
                <img
                  src="/Images/LandingPage/brilliantBee/topology.png"
                  alt="Brilliant bee features"
                  className="outcomes__holder__card__top__img"
                />
              </div>
              <ul className="outcomes__holder__card__list">
                <li>Supporting videos explaining various concepts </li>
                <li>
                  Webinars and online meets for group discussions, doubt
                  clarification and debates
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
      <div className="outcomes kitcontrntbtn">
        <h2 className="outcomes__head kitcontrntbtn2">
          KNOW MORE ABOUT KIT CONTENTS
        </h2>
        <Link to="/kitcontent">
          <button
            className="header-btn pageheader__btn"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <p>KIT CONTENTS</p>
          </button>
        </Link>
      </div>
    </>
  );
};

export default BuilderbeeOutcomes;
