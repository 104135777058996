import "./header.scss";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";
const Jobcard = ({ data, setOpportunityFromCard }) => {
  return (
    <Fade bottom>
      <div className="jobsection__card">
        <div className="jobsection__card__base">
          <p className="jobsection__card__head">{data.jobtitle}</p>
          <p className="jobsection__card__type">{data.type}</p>
          <p className="jobsection__card__text">{data.description}</p>
        </div>
        <Link to="contact">
          <button className="jobsection__card__apply">APPLY NOW</button>
        </Link>
      </div>
    </Fade>
  );
};

export default Jobcard;
