import CompCard from "./compCard";
import "./competition.scss";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Competition = () => {
  return (
    <div className="competition">
      <div className="competition__col1">
        <img
          effect="blur"
          src="/Images/LandingPage/comp-img2.png"
          alt="Boy holding Flag"
          className="competition__img"
        />
      </div>
      <div className="competition__col2">
        <CompCard />
      </div>
    </div>
  );
};

export default Competition;
/*
{
  competition ? (
              <>
                <div className="competition-card__guidlines">
                  <div className="competition-card__section">
                    <div className="number-holder">1</div>
                    <p className="competition-card__section__text">
                      Kids can present any kind of their creative work based on
                      the theme. Lorem ipsum etur adipisicing elit. Quidem
                    </p>
                  </div>
                  <div className="competition-card__section">
                    <div className="number-holder">2</div>
                    <p className="competition-card__section__text">
                      Upload submissions on or before 3rd May 2021.voluptate
                      labore veniam laudita, fugit culpa pariatur dolor?
                    </p>
                  </div>
                </div>
                <p className="competition-card__text2 competition-card__text">
                  Details about date and age.Repeat chunks as necenter
                </p>
                <button className="header-btn competition-card__btn">
                  <p>REGISTER NOW</p>
                </button>
              </>
            ) : (
              <div className="competition-result">
                <p className="competition-result__congrats">CONGRATULATIONS!</p>
                <div className="competition-result__holder">
                  <div className="competition-result__holder__item">
                    <img
                      src="https://randomuser.me/api/portraits/men/6.jpg"
                      alt="User Detail"
                      className="competition-result__holder__item__img"
                    />
                    <p className="competition-result__holder__item__name">
                      Martin T Varghese
                    </p>
                    <p className="competition-result__holder__item__text">
                      SomeText
                    </p>
                    <p className="competition-result__holder__item__text competition-result__holder__item__text2">
                      First Prize
                    </p>
                  </div>
                  <div className="competition-result__holder__item">
                    <img
                      src="https://randomuser.me/api/portraits/men/16.jpg"
                      alt="User Detail"
                      className="competition-result__holder__item__img"
                    />
                    <p className="competition-result__holder__item__name">
                      Martin T Varghese
                    </p>
                    <p className="competition-result__holder__item__text">
                      SomeText
                    </p>
                    <p className="competition-result__holder__item__text competition-result__holder__item__text2">
                      Second Prize
                    </p>
                  </div>
                  <div className="competition-result__holder__item">
                    <img
                      src="https://randomuser.me/api/portraits/men/10.jpg"
                      alt="User Detail"
                      className="competition-result__holder__item__img"
                    />
                    <p className="competition-result__holder__item__name">
                      Martin T Varghese
                    </p>
                    <p className="competition-result__holder__item__text">
                      SomeText
                    </p>
                    <p className="competition-result__holder__item__text competition-result__holder__item__text2">
                      Third Prize
                    </p>
                  </div>
                </div>
                <p className="competition-result__congrats2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </p>
              </div>
            )}

            */
