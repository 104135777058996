import "./bibliobee.scss";
import Fade from "react-reveal/Fade";

const BibliobeeOutcomes = () => {
  return (
    <>
      <div className="outcomes ">
        <h2 className="outcomes__head">COURSE INCLUDES</h2>
        <div className="outcomes__holder">
          <Fade bottom>
            <div className="outcomes__holder__card">
              <div className="outcomes__holder__card__top outcomes__holder__card__top2">
                <h5>
                  Read <br />
                  and Learn
                </h5>
                <img
                  src="/Images/LandingPage/brilliantBee/bookmark.png"
                  alt="Brilliant bee features"
                  className="outcomes__holder__card__top__img"
                />
              </div>
              <ul className="outcomes__holder__card__list">
                <li>
                  Kids will learn to read out aloud and thereby understanding
                  phonetics
                </li>
                <li>
                  Pronunciations and developing fluency, modulations and
                  expression while speaking
                </li>
              </ul>
            </div>
          </Fade>
          <Fade bottom>
            <div className="outcomes__holder__card">
              <div className="outcomes__holder__card__top">
                <h5>
                  Reading <br />
                  as a Hobby
                </h5>
                <img
                  src="/Images/LandingPage/brilliantBee/school-bag.png"
                  alt="Brilliant bee features"
                  className="outcomes__holder__card__top__img"
                />
              </div>
              <ul className="outcomes__holder__card__list">
                <li>
                  Helps to inculcate a reading habit which is a much better
                  hobby to have than sticking to screens.
                </li>
                <li>Improve communication</li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default BibliobeeOutcomes;
