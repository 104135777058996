//import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";
import "./allcourses.scss";
const Allcourses = () => {
  return (
    <div className="career-header  coursespage">
      <Fade left>
        <h2 className="pageheader__head">COURSES</h2>
      </Fade>
      <Fade left delay={1000}>
        <p className="career-header__text">
          Courses offered by BeylaBee for students
        </p>
      </Fade>
    </div>
  );
};

export default Allcourses;
