import "./kitcintent.scss";
import Fade from "react-reveal/Fade";
import { content } from "./kitcontent";
import { useState } from "react";
import Kitclassitem from "./kitclassitem";
const KitClass = () => {
  const [classdata, setClass] = useState(content[0]);
  function setClassDetails(index) {
    setClass(content[index]);
  }

  return (
    <div className="kitclass">
      <Fade bottom>
        <h2 className="outcomes__head kitclass__head">
          MONTHLY BUILDABLES <span>SELECT YOUR CLASS</span>
        </h2>
      </Fade>
      <Fade>
        <div className="selectclass">
          <div
            className="selectclass__item"
            onClick={() => {
              setClassDetails(0);
            }}
          >
            6
          </div>
          <div
            className="selectclass__item"
            onClick={() => {
              setClassDetails(1);
            }}
          >
            7
          </div>
          <div
            className="selectclass__item"
            onClick={() => {
              setClassDetails(2);
            }}
          >
            8
          </div>
          <div
            className="selectclass__item"
            onClick={() => {
              setClassDetails(3);
            }}
          >
            9
          </div>
          <div
            className="selectclass__item"
            onClick={() => {
              setClassDetails(4);
            }}
          >
            10
          </div>
        </div>
      </Fade>
      <div className="kitclass__holder">
        {classdata[0].map((item, index) => {
          return (
            <Fade bottom key={index}>
              <Kitclassitem
                head={item}
                sub={classdata[1][index]}
                index={index}
              />
            </Fade>
          );
        })}
      </div>
    </div>
  );
};

export default KitClass;
