import Fade from "react-reveal/Fade";
import "./builderbee.scss";
import { Link } from "react-scroll";
const Builderbeepage = () => {
  return (
    <div className="pageheader pageheader3">
      <div className="pageheader__col1">
        <Fade left>
          <h2 className="pageheader__head">BUILDER BEE</h2>
        </Fade>
        <Fade left delay={1000}>
          <p className="pageheader__text">
            This is an initiative to inculcate a hands-on learning culture as
            opposed to a largely theoretical system by means of a monthly
            subscription model of project-based learning kits. Hands-on learning
            increases long-term retention, improves problem-solving and
            collaboration skills and improves students' attitude towards
            learning. The kits are easy to understand and aims to fuel a child's
            curiosity so he/she learns to dig deeper than just the texts in
            their books and ask the how, what and why of things.
          </p>
        </Fade>
        <Fade bottom delay={1000}>
          <Link to="contact">
            <button className="header-btn pageheader__btn">
              <p>BOOK FREE DEMO CLASS</p>
            </button>
          </Link>
        </Fade>
      </div>
      <div className="pageheader__col2">
        <Fade bottom delay={1500}>
          <div className="pageheader__card">
            <p className="pageheader__card__text">
              For every <span>KIDS</span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Fees
              <span>
                16,000
                <span className="pageheader__card__text2">+GST</span>
              </span>
              <span className="pageheader__card__text2">
                or 2000+ GST per box
              </span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              One Kit per Month for
              <span>
                10<span className="pageheader__card__text2">Month</span>
              </span>
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Builderbeepage;
