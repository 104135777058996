import { LazyLoadImage } from "react-lazy-load-image-component";
import "./about.scss";
import Fade from "react-reveal/Slide";

const About = () => {
  return (
    <div className="about">
      <div className="about__col1">
        <p className="quoteicn">“</p>
        <Fade left>
          <p className="quote">
            If We Teach Today's Students As We Taught Yesterday’s We Rob Them Of
            A Bright Tomorrow
          </p>
        </Fade>
        <Fade bottom>
          <p className="author">John Dewey</p>
        </Fade>
      </div>
      <div className="about__col2">
        <Fade>
          <LazyLoadImage
            src="/Images/LandingPage/girlstudying.png"
            alt="Mockup"
            effect="blur"
            className="about__img"
          />
        </Fade>
      </div>
    </div>
  );
};

export default About;

/*

const ref = firebase.firestore().collection("userreg");
function getData() {
  ref.onSnapshot((querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });

    setData(items);
    console.log(data[0].Name);
  });
}

useEffect(() => {
  getData();
}, []);*/
