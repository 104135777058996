import Fade from "react-reveal/Fade";
import "./bibliobee.scss";
import { Link } from "react-scroll";
const Bibliobeepage = () => {
  return (
    <div className="pageheader pageheader3">
      <div className="pageheader__col1">
        <Fade left>
          <h2 className="pageheader__head">BIBLIO BEE</h2>
        </Fade>
        <Fade left delay={1000}>
          <p className="pageheader__text">
            This program is carefully designed in order to introduce and instill
            a reading habit in students from a young age. (It is a known fact
            that reading helps strengthen the brain, build vocabulary, increase
            creativity and empathy and also prevents cognitive decline.) When
            reading habit is cultivated from a young age it stimulates curiosity
            in a child, develops the child's communication skills, increases
            their concentration, improves the attention span and aids memory
            retention.
          </p>
        </Fade>
        <Fade bottom delay={1000}>
          <Link to="contact">
            <button className="header-btn pageheader__btn">
              <p>BOOK FREE DEMO CLASS</p>
            </button>
          </Link>
        </Fade>
      </div>
      <div className="pageheader__col2">
        <Fade bottom delay={1500}>
          <div className="pageheader__card">
            <p className="pageheader__card__text">
              For Classes <span>2,3,4,5</span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Fees
              <span>
                2000
                <span className="pageheader__card__text2">+GST per month</span>
              </span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Number Of Classes
              <span>
                8<span className="pageheader__card__text2">/Month</span>
              </span>
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Bibliobeepage;
