import "./kitcintent.scss";
import Fade from "react-reveal/Fade";
const KitcontentHeader = () => {
  return (
    <div className="career-header kitcontent-page">
      <Fade left>
        <h2 className="pageheader__head">KIT CONTAINS</h2>
      </Fade>
      <Fade left delay={1000}>
        <p className="career-header__text">
          Monthly subscription model of project-based learning kits
        </p>
      </Fade>
    </div>
  );
};

export default KitcontentHeader;
