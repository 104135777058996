import "./stem.scss";
//import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";
const Stem = () => {
  return (
    <div className="stem" name="about" id="about">
      <Fade bottom>
        <div className="stem__card glass">
          <h2 className="stem__card__head head">STEM Learning and Why ?</h2>
          <p className="stem__card__para">
            Stem learning increases long-term retention improves cognitive and
            problem-solving abilities, collaboration and social skills and
            improve students' attitude towards learning. The current education
            has an undue focus on theory and neither encourages the practical
            implementation of a subject nor the use of social skills. We need to
            integrate knowing and doing where the children learn to put their
            theory to the test in order to solve authentic problems to produce
            results that matter.
          </p>
          <div className="stem__card__bottom">
            <img
              effect="blur"
              src="/Images/LandingPage/kitten.png"
              alt="Boy holding Flag"
              className="stem__card__bottom__img"
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Stem;
