import "./header.scss";
import { useEffect, useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import Skeleton from "@material-ui/lab/Skeleton";
import firebase from "../../../firebase/firebase";
const InternCard = () => {
  const ref = firebase.firestore().collection("intern");
  const [intern, setInterndata] = useState([]);
  const [interndataload, setInterndataload] = useState(true);
  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setInterndata(items[0]);
      setInterndataload(false);
    });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <Fade bottom delay={1000}>
      <div className="interncard">
        <p className="career-section__head interncard__head">
          INTERN OF THE MONTH
        </p>

        {interndataload ? (
          <Skeleton variant="rect" width={150} height={150} />
        ) : (
          <img src={intern.img} alt="" className="interncard__img" />
        )}

        <p className="career-section__text interncard__intern">
          {interndataload ? <Skeleton variant="text" /> : intern.internname}
        </p>
        <p className="career-section__text interncard__institute">
          {interndataload ? <Skeleton variant="text" /> : intern.college}
        </p>

        {interndataload ? (
          <Skeleton variant="circle" width={50} height={50} />
        ) : (
          <Link to={intern.linkdin} className="interncard__icn">
            <FaLinkedin size="2.5em" color="#0077b5" />
          </Link>
        )}
      </div>
    </Fade>
  );
};

export default InternCard;
