import Navbar from "../Nav/nav";
import BrilliantBeepage from "../brilliantbee/pageheader";
import BrillianbeeOutcomes from "../brilliantbee/brillianbeeoutcomes";
import Footer from "../footer/footer";
import styled from "styled-components";
import SuperContact from "../universal-contact/super-contact";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
const BrilliantBee = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />
      <BrilliantBeepage />
      <Section>
        <BrillianbeeOutcomes />
        <SuperContact type={"brillianbee"} />
      </Section>
      <Footer />
    </>
  );
};

export default BrilliantBee;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
