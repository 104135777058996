import Navbar from "../Nav/nav";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
import Footer from "../footer/footer";
import styled from "styled-components";
import Careerheader from "../career/careerheader/header";
import Careersection from "../career/careerheader/section";

const Career = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />
      <Careerheader />
      <Careersection />
      <Section></Section>
      <Footer />
    </>
  );
};

export default Career;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
