import Navbar from "../Nav/nav";

import Footer from "../footer/footer";
import styled from "styled-components";
import KitcontentHeader from "../kitcontent-page/kitcintent";
import KitcontentOutcomes from "../kitcontent-page/kitcontentoutcomes";
import KitClass from "../kitcontent-page/kitclass";
import SuperContact from "../universal-contact/super-contact";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
const KitcontentPage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />

      <KitcontentHeader />
      <Section>
        <KitcontentOutcomes />
        <KitClass />
        <SuperContact type={"KitContent"} />
      </Section>

      <Footer />
    </>
  );
};

export default KitcontentPage;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
