import Navbar from "../Nav/nav";

import Footer from "../footer/footer";
import styled from "styled-components";

import SuperContact from "../universal-contact/super-contact";

import Scholarbeepage from "../Scholarbee/pageheader";
import ScholarbeeOutcomes from "../Scholarbee/scholarbeeoutcome";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
const Scholarbee = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />

      <Scholarbeepage />
      <Section>
        <ScholarbeeOutcomes />
        <SuperContact type={"Scholarbee"} />
      </Section>
      <Footer />
    </>
  );
};

export default Scholarbee;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
