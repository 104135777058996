//import { LazyLoadImage } from "react-lazy-load-image-component";
import "./advantages.scss";
import Fade from "react-reveal/Fade";
const Advantages = () => {
  return (
    <div className="advantage">
      <div className="advantage__col1">
        <img
          effect="blur"
          src="/Images/LandingPage/flying.png"
          alt="Flying"
          className="advantage__img"
        />
      </div>
      <div className="advantage__col2">
        <Fade right>
          <div className="advantage__card glass">
            <h2 className="stem__card__head head">Our Aim and Approach</h2>
            <p className="stem__card__para advantage__para">
              Our project-based education approach helps students apply what
              they learn in school to real time problems and find ways to tackle
              them. We aim to enhance the child's thought process and fuel their
              curiosity. We want to make learning 'intelligent and interesting'
              as opposed to 'regular and routine'.
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Advantages;
