import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
const AllCourseslist = () => {
  const history = useHistory();
  return (
    <div className="outcomes">
      <h2 className="outcomes__head">AWESOME COURSES FROM BEYLABEE</h2>
      <div className="outcomes__holder">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top">
              <h5>
                BUILDER BEE<br></br>&nbsp;
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/puzzle-piece.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                Initiative to inculcate a hands-on learning culture by means of
                a monthly project-based learning kits.
              </li>
              <li>Monthly subscription model of project-based learning kits</li>
            </ul>
            <button
              className="course-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/builderbee");
              }}
            >
              KNOW MORE
            </button>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top ">
              <h5>
                BRILLIANT BEE<br></br>&nbsp;
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/matrix.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                Dealing with topics such as quantitative aptitude, logical
                reasoning, verbal proficiency
              </li>
              <li>Preparing for and attempting various competitive exams</li>
            </ul>

            <button
              className="course-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/brilliantbee");
              }}
            >
              KNOW MORE
            </button>
          </div>
        </Fade>
      </div>
      <div className="outcomes__holder outcomes__holder3 ">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                CODER BEE<br></br>&nbsp;
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/logic.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                Introducing the programming languages ‘Python’ and ‘JavaScript’
                using cool projects and Activities.
              </li>
              <li>
                Coding will help communicate across countries and cultures
              </li>
            </ul>
            <button
              className="course-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/coderbee");
              }}
            >
              KNOW MORE
            </button>
          </div>
        </Fade>
      </div>

      <div className="outcomes__holder outcomes__holder3 ">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                BIBLIO BEE<br></br>&nbsp;
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/combinatorics.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                This program is carefully designed in order to introduce and
                instill a reading habit in students from a young age.
              </li>
              <li>
                Helps to inculcate a reading habit which is a much better hobby
                to have than sticking to screens.
              </li>
            </ul>

            <button
              className="course-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/bibliobee");
              }}
            >
              KNOW MORE
            </button>
          </div>
        </Fade>
      </div>

      <div className="outcomes__holder  ">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top ">
              <h5>
                SCHOLAR BEE<br></br>&nbsp;
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/set-square.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                This program is aimed at teaching students school-level Maths
                and Science in order to help them understand the concepts better
              </li>
              <li>
                Know the importance of the subject in real life. We provide
                personalized one-on-one live classes
              </li>
            </ul>

            <button
              className="course-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/scholarbee");
              }}
            >
              KNOW MORE
            </button>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                LINGUIST BEE
                <br />
                &nbsp;
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/hi.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                Designed to develop a keen interest among students to learn
                different languages.
              </li>
              <li>
                Improved concentration, superior multitasking abilities, better
                critical thinking
              </li>
            </ul>
            <button
              className="course-btn"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/linguistbee");
              }}
            >
              KNOW MORE
            </button>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default AllCourseslist;
