import "./super-contact.scss";
import { ImWhatsapp } from "react-icons/im";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import React from "react";
import Fade from "react-reveal/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
////////////////////////Firebase
import firebase from "../../firebase/firebase";
import { useState } from "react";
///////MaterialUI

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={4} variant="filled" {...props} />;
}

//////////////
const SuperContact = ({ type, data }) => {
  //////////////////////////FirebaseVar

  const ref = firebase.firestore().collection("userreg"); ////Firebase connection to Db
  const refcareer = firebase.firestore().collection("jobrequest"); ////Firebase connection to Db
  const [name, setName] = useState("");
  const [userclass, setClass] = useState("");
  const [num, setNum] = useState("+91");
  const [resume, setResume] = useState("");
  const [mail, setMail] = useState("");
  const [selectedjob, setSelectedjob] = useState("");
  const [isLoading, setLoad] = useState(false);
  const [isFormError, setFormError] = useState(false);

  function initialState() {
    setName("");
    setClass("");
    setNum("+91");
    setMail("");
    setResume("");
    setTimeout(() => {
      setOpen(false);
    }, 1500);
  }
  //////////////////Setup the Dropdown Menu
  let courses = [];
  if (type === "Liguistbee") {
    courses = ["Basic German", "Basic French"];
  } else if (type === "Coderbee") {
    courses = ["Python", "JavaScript"];
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [liguistCourse, setCourse] = React.useState(courses[0]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setCourse(courses[value]);
    setAnchorEl(null);
  };
  const handleJobClose = (value) => {
    setSelectedjob(data[value]);
    setAnchorEl(null);
  };
  //Set The menu Selected value
  /////////////////////////////////////
  //////////////////Firebase UploadData
  function handleSubitForm(e) {
    e.preventDefault();

    if (type === "career") {
      uploadCareerData();
    } else {
      uploadData();
    }
  }
  function uploadData() {
    if (name.length <= 3) {
      setFormError("Enter Your Full Name!");
    } else if (num.length < 13) {
      setFormError("Enter A Valid Contact Number!");
    } else if (userclass.length <= 0) {
      setFormError("Enter Class Details!");
    } else {
      setFormError(false);
      const data = {
        name: name,
        userclass: userclass,
        num: num,
        category: type,

        subcategory: liguistCourse ? liguistCourse : "nodata",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      setLoad(true);
      ref
        .doc()
        .set(data)
        .then(() => {
          setOpen(true);
          setStatus("success");
          setLoad(false);
          initialState();
        })
        .catch((err) => {
          setStatus("error");
          setLoad(false);
          initialState();
        });
    }
  }
  ///////////////////////Upload Career Form
  function uploadCareerData() {
    if (name.length <= 3) {
      setFormError("Enter Your Full Name!");
    } else if (num.length < 10) {
      setFormError("Enter A Valid Contact Number!");
    } else if (mail.length <= 5 || !mail.includes("@") || !mail.includes(".")) {
      setFormError("Enter valid Email");
    } else if (!selectedjob) {
      setFormError("Select one Opportunity");
    } else {
      setFormError(false);
      const data = {
        name: name,
        mail: mail,
        phone: num,
        job: selectedjob,
        type: selectedjob.includes("Intern") ? "Intern" : "Full Time",
        resume: resume ? resume : "nodata",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      setLoad(true);
      refcareer
        .doc()
        .set(data)
        .then(() => {
          setOpen(true);
          setStatus("success");
          setLoad(false);
          initialState();
        })
        .catch((err) => {
          setStatus("error");
          setLoad(false);
          initialState();
        });
    }
  }
  /////////////////////////////////////
  //////////////////Snackbar
  const [open, setOpen] = React.useState(false);

  const handleHideSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [status, setStatus] = useState("success");
  /////////////////////////////////////
  return (
    <>
      <div className="supercontact" name="contact">
        <div className="supercontact___col">
          <Fade bottom>
            <form className="supercontact__form">
              <h2 className="head contact__head supercontact__form__head">
                {!(type === "career")
                  ? type
                    ? "Enroll Now"
                    : "Contact Us"
                  : "Work With BeylaBee"}
              </h2>
              <div className="supercontact__form__holder">
                <label htmlFor="" className="inputfield__label">
                  Your Name
                </label>
                <input
                  type="text"
                  className="inputfield"
                  placeholder="Full Name"
                  value={name}
                  onChange={async (e) => {
                    await setName(e.target.value);
                  }}
                />
              </div>

              <div className="supercontact__form__holder">
                <label htmlFor="" className="inputfield__label">
                  Your Contact Number
                </label>
                <input
                  type="tel"
                  placeholder="Your Contact Number"
                  className="inputfield"
                  value={num}
                  onChange={async (e) => {
                    await setNum(e.target.value);
                  }}
                />
              </div>
              {type === "career" ? (
                <>
                  <div className="supercontact__form__holder">
                    <label htmlFor="" className="inputfield__label">
                      Your Email
                    </label>
                    <input
                      type="mail"
                      placeholder="Mail"
                      className="inputfield"
                      onChange={async (e) => {
                        await setMail(e.target.value);
                      }}
                      value={mail}
                    />
                  </div>
                  <div className="supercontact__form__holder">
                    <label htmlFor="" className="inputfield__label">
                      Your Resume Link
                    </label>
                    <input
                      type="link"
                      placeholder="Drive,Website Link etc"
                      className="inputfield"
                      onChange={async (e) => {
                        await setResume(e.target.value);
                      }}
                      value={resume}
                    />
                  </div>
                </>
              ) : (
                <div className="supercontact__form__holder">
                  <label htmlFor="" className="inputfield__label">
                    Your Class
                  </label>
                  <input
                    type="text"
                    placeholder="8th"
                    className="inputfield"
                    onChange={async (e) => {
                      await setClass(e.target.value);
                    }}
                    value={userclass}
                  />
                </div>
              )}

              {type === "Liguistbee" || type === "Coderbee" ? (
                <div className="supercontact__form__holder">
                  <label htmlFor="" className="inputfield__label">
                    {type} Course
                  </label>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <p className="menu-item2">{liguistCourse}</p>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => {
                      handleClose();
                    }}
                  >
                    {courses.map((item, index) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            handleClose(index);
                          }}
                        >
                          <p className="menu-item">{item}</p>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              ) : null}

              {type === "career" ? (
                <div className="supercontact__form__holder">
                  <label htmlFor="" className="inputfield__label">
                    Opportunity
                  </label>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <p className="menu-item2">
                      {selectedjob ? selectedjob : "Select Job"}
                    </p>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => {
                      handleJobClose();
                    }}
                  >
                    {data.map((item, index) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            handleJobClose(index);
                          }}
                          key={item + index}
                        >
                          <p className="menu-item">{item}</p>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              ) : null}

              {isFormError ? (
                <div className="form-error">{isFormError}</div>
              ) : null}

              <div className="supercontact__form__holder2">
                <button
                  className="supercontact__form__holder__btn"
                  onClick={handleSubitForm}
                  disabled={isLoading}
                >
                  {!type ? "SEND" : "REGISTER"}
                </button>
                <div className="supercontact__form__holder2__holder">
                  <p className="supercontact__form__holder2__text">
                    or Message on
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://api.whatsapp.com/send/?phone=%2B918086324545&text=Hi%2C+Know+More+About+Beyla",
                        "_blank"
                      );
                    }}
                    className="supercontact__form__holder__btn supercontact__form__holder__btn2"
                  >
                    WHATSAPP
                    <ImWhatsapp className="supercontact__form__holder__icn" />
                  </button>
                </div>
              </div>
              {isLoading ? (
                <div className="circularholder">
                  <CircularProgress color="secondary" />
                </div>
              ) : null}
            </form>
          </Fade>
        </div>
        <div className="supercontact__col2 ">
          <img
            effect="blur"
            src="/Images/LandingPage/Saly-5.png"
            alt="Boy holding Flag"
            className="supercontact__col2__img1"
          />
        </div>
      </div>

      <Snackbar
        Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
      >
        <Alert
          autoHideDuration={6000}
          onClose={() => {
            setOpen(false);
          }}
          severity={status}
        >
          <p className="alerttext">
            {status === "success"
              ? "Registered Succesfully"
              : "Something Went Wrong"}
          </p>
        </Alert>
      </Snackbar>
    </>
  );
};

export default SuperContact;
