import "./pageheader.scss";
import Fade from "react-reveal/Fade";
const BrillianbeeOutcomes = () => {
  return (
    <div className="outcomes">
      <h2 className="outcomes__head">LEARNING OUTCOMES</h2>
      <div className="outcomes__holder">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                Quantitative<br></br>Aptitude
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/matrix.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                To test analytical and problem-solving skills solve numerical
                and mathematical calculations
              </li>
              <li>
                Improve a student’s ability to sense a given data and become at
                ease with numbers
              </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top">
              <h5>
                Logical<br></br>Reasoning
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/logic.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                To develop the ability to understand what they read or see and
                build upon it to assess situations or problems
              </li>
              <li>To provide structured reasons and evidence </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                Mathematical<br></br>Reasoning
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/set-square.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                To help students realize that mathematics makes sense and can be
                understood
              </li>
              <li>Enhance critical thinking to derive rational conclusions</li>
            </ul>
          </div>
        </Fade>
      </div>
      <div className="outcomes__holder outcomes__holder2">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top">
              <h5>
                Data<br></br>Interpretation
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/function.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                To analyze comprehensive numerical data mostly in the form of
                graphs, tables, pie charts etc.
              </li>
              <li>
                To derive relevant inferences and conclusions that is later used
                to tackle problems
              </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                Verbal <br></br>Reasoning
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/blackboard.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>
                Help students develop the ability to comprehend what they read
                or hear, to draw inferences from limited data using
                language-based reasoning
              </li>
              <li>
                Add meaning to what they read and to make informed predictions
                based on what they read
              </li>
            </ul>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default BrillianbeeOutcomes;
