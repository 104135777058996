import "./pageheader.scss";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";
const BrilliantBeepage = () => {
  return (
    <div className="pageheader pageheader1">
      <div className="pageheader__col1">
        <Fade left>
          <h2 className="pageheader__head">BRILLIANT BEE</h2>
        </Fade>
        <Fade left delay={1000}>
          <p className="pageheader__text">
            This is a course dealing with topics such as quantitative aptitude,
            logical reasoning, verbal proficiency etc. in order to build a
            strong foundation for students’ rational and critical thinking
            skills that will be highly useful in preparing for and attempting
            various competitive exams such as bank and railway exams, UPSC
            prelims, GATE, UGC, Management exams etc. in the future.
          </p>
        </Fade>
        <Fade bottom delay={1000}>
          <Link to="contact">
            <button className="header-btn pageheader__btn">
              <p>BOOK FREE DEMO CLASS</p>
            </button>
          </Link>
        </Fade>
      </div>
      <div className="pageheader__col2">
        <Fade bottom delay={1500}>
          <div className="pageheader__card">
            <p className="pageheader__card__text">
              For classes <span>8,9,10</span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Fees
              <span>
                18,000<span className="pageheader__card__text2">+gst</span>
              </span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Total classes of
              <span>
                160
                <span className="pageheader__card__text2">hours</span>
              </span>
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default BrilliantBeepage;
