import React from "react";
import { NavLink } from "react-router-dom";
import "./nav.scss";
import { useLocation } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import { IoChevronDown, IoCloseOutline } from "react-icons/io5";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
const Navbar = () => {
  const [navvisible, setNav] = useState(false);
  const nav = document.getElementsByClassName("mobilenav");
  const location = useLocation();

  let history = useHistory();
  return (
    <>
      <nav className="nav">
        <NavLink to="/">
          <img
            src="/Images/LandingPage/logobeyla.png"
            alt="Logo"
            className="nav__img"
          />
        </NavLink>
        <ul className="nav__links">
          <li>
            <NavLink to="/" className="nav__item">
              Home
            </NavLink>
          </li>
          <li>
            {location.pathname === "/" ? (
              <Link to="about" className="nav__item">
                About
              </Link>
            ) : (
              <NavLink
                to="/#about"
                className="nav__item"
                onClick={() => {
                  window.scrollTo(0, 3000);
                }}
              >
                About
              </NavLink>
            )}
          </li>
          <li>
            <NavLink to="/career" className="nav__item">
              Career
            </NavLink>
          </li>

          <li>
            <div className="dropdown-cont">
              <p className="nav__item flexbox-center more">
                Courses <IoChevronDown className="nav__icn" />
              </p>
              <div className="dropdown">
                <ul>
                  <li>
                    <NavLink to="/brilliantbee" className="link-dropdown">
                      <div className="dropdown-item">
                        <img
                          src="/Images/LandingPage/brilliantBee/matrix.png"
                          alt="Matrix"
                          className="dropdown-item__img"
                        />
                        <div className="dropdown-item__col">
                          <p className="dropdown-item__col__head">
                            BRILLIANT BEE
                          </p>
                          <p className="dropdown-item__col__text">
                            Dealing with topics such as quantitative aptitude,
                            logical reasoning, verbal proficiency
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/coderbee" className="link-dropdown">
                      <div className="dropdown-item">
                        <img
                          src="/Images/LandingPage/brilliantBee/logic.png"
                          alt="Code"
                          className="dropdown-item__img"
                        />
                        <div className="dropdown-item__col">
                          <p className="dropdown-item__col__head">CODER BEE</p>
                          <p className="dropdown-item__col__text">
                            Introducing the programming languages ‘Python’ and
                            ‘JavaScript’ using cool projects and Activities.
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/linguistbee" className="link-dropdown">
                      <div className="dropdown-item">
                        <img
                          src="/Images/LandingPage/brilliantBee/hi.png"
                          alt="Linguistbee"
                          className="dropdown-item__img"
                        />
                        <div className="dropdown-item__col">
                          <p className="dropdown-item__col__head">
                            LINGUIST BEE
                          </p>
                          <p className="dropdown-item__col__text">
                            This programme is designed to develop a keen
                            interest among students to learn different
                            languages.
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/builderbee" className="link-dropdown">
                      <div className="dropdown-item">
                        <img
                          src="/Images/LandingPage/brilliantBee/puzzle-piece.png"
                          alt="Puzzle"
                          className="dropdown-item__img"
                        />
                        <div className="dropdown-item__col">
                          <p className="dropdown-item__col__head">
                            BUILDER BEE
                          </p>
                          <p className="dropdown-item__col__text">
                            Initiative to inculcate a hands-on learning culture
                            by means of a monthly project-based learning kits.
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/bibliobee" className="link-dropdown">
                      <div className="dropdown-item">
                        <img
                          src="/Images/LandingPage/brilliantBee/combinatorics.png"
                          alt="Puzzle"
                          className="dropdown-item__img"
                        />
                        <div className="dropdown-item__col">
                          <p className="dropdown-item__col__head">BIBLIO BEE</p>
                          <p className="dropdown-item__col__text">
                            This program is carefully designed in order to
                            introduce and instill a reading habit
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <CgMenu
          size="4em"
          className="nav__icnmobile"
          onClick={() => {
            setNav(true);
          }}
        />
        <Link to="contact" className="navbtnholder" duration={1000}>
          <button className="nav__btn">ContactUs</button>
        </Link>
      </nav>
      <div
        className={`mobilenav ${
          navvisible ? "mobilenav-show" : "mobilenav-hide"
        }`}
        onBlur={() => {
          setNav(false);
        }}
      >
        <IoCloseOutline
          size="5em"
          className="mobilenav__icn"
          onClick={() => {
            setNav(false);
          }}
        />
        <ul className="mobilenav__list">
          <li>
            <NavLink
              to="/"
              className="mobilenav__item"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/");
              }}
            >
              Home
            </NavLink>
          </li>

          {location.pathname === "/" ? (
            <Link to="about" className="mobilenav__item">
              About
            </Link>
          ) : (
            <NavLink
              to="/#about"
              className="mobilenav__item"
              onClick={() => {
                window.scrollTo(0, 3000);
              }}
            >
              About
            </NavLink>
          )}
          <li>
            <NavLink
              to="/career"
              className="mobilenav__item"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/career");
              }}
            >
              Career
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/courses"
              className="mobilenav__item"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push("/courses");
              }}
            >
              Courses
            </NavLink>
          </li>
          <li>
            <Link
              to="contact"
              className="mobilenav__item mobilenav__item2"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              ContactUs
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
