import "./linguistbee.scss";
import Fade from "react-reveal/Fade";
const LinguistbeeOutcomes = () => {
  return (
    <div className="outcomes">
      <h2 className="outcomes__head">OUR COURSES</h2>
      <div className="outcomes__holder">
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top outcomes__holder__card__top2">
              <h5>
                Basic <br></br>German
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/ger.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>Children will learn: Basic German greetings and sentences</li>
              <li>
                Basic grammar ,Basic reading and writing, Basic translations and
                Basic speech
              </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card">
            <div className="outcomes__holder__card__top">
              <h5>
                Basic <br></br>French
              </h5>
              <img
                src="/Images/LandingPage/brilliantBee/eng.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list">
              <li>Children will learn: Basic French greetings and sentences</li>
              <li>
                Basic grammar ,Basic reading and writing, Basic translations and
                Basic speech
              </li>
            </ul>
          </div>
        </Fade>
        <Fade bottom></Fade>
      </div>
    </div>
  );
};

export default LinguistbeeOutcomes;
