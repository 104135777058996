import Fade from "react-reveal/Fade";
import "./Coderbee.scss";
import { Link } from "react-scroll";
const Coderbeepage = () => {
  return (
    <div className="pageheader pageheader4">
      <div className="pageheader__col1">
        <Fade left>
          <h2 className="pageheader__head">CODER BEE</h2>
        </Fade>
        <Fade left delay={1000}>
          <p className="pageheader__text">
            A curriculum built to equip students with skills that will help them
            keep in pace with the booming Tech World where coding has now become
            the universal language. Coding will help communicate across
            countries and cultures, develop our analytical side of the brain, be
            creative and innovative, build solutions and solve problems more
            systematically. Learning programming at a young age opens a lifetime
            of opportunities for kids.
          </p>
        </Fade>
        <Fade bottom delay={1000}>
          <Link to="contact">
            <button className="header-btn pageheader__btn">
              <p>BOOK FREE DEMO CLASS</p>
            </button>
          </Link>
        </Fade>
      </div>
      <div className="pageheader__col2">
        <Fade bottom delay={1500}>
          <div className="pageheader__card">
            <p className="pageheader__card__text">
              For Standard <span>6-12</span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              One-time payment per Course
              <span>
                16,000
                <span className="pageheader__card__text2">+GST</span>
              </span>
            </p>
            <div className="line"></div>
            <p className="pageheader__card__text pageheader__card__texts2">
              Course Duration
              <span>
                36<span className="pageheader__card__text2">Hours</span>
              </span>
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Coderbeepage;
