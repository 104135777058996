import "./Coderbee.scss";
import Fade from "react-reveal/Fade";
const CoderbeeOutcomes = () => {
  return (
    <div className="outcomes">
      <h2 className="outcomes__head">PROGRAMMING COURSES</h2>
      <div className="outcomes__holder outcomes__holder2">
        <Fade bottom className="hldr">
          <div className="outcomes__holder__card outcomes__holder__card2">
            <div className="outcomes__holder__card__top">
              <h5>JavaScript</h5>
              <img
                src="/Images/LandingPage/javascript.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list ">
              <li>
                In this course the students will be introduced to ‘Web
                development’ progressing from basic to advanced levels; based on
                which the students will build one active web page on their own,
                and create one website during class.<br></br>&nbsp;
              </li>
            </ul>
            <p className="outcomes__holder__card__heading">
              <span>Level 1</span>3000₹+GST
            </p>
            <ul className="outcomes__holder__card__list outcomes__holder__card__list2">
              <li>Introduction to html, CSS</li>
              <li>Activities based on the topics</li>
              <li>Main project begins</li>
            </ul>
            <div className="divider"></div>
            <p className="outcomes__holder__card__heading">
              <span>Level 2</span>5000₹+GST
            </p>
            <ul className="outcomes__holder__card__list outcomes__holder__card__list2">
              <li>Advanced web development</li>
              <li>Activities based on the topics</li>
              <li>Main project cont.</li>
            </ul>
            <div className="divider"></div>
            <p className="outcomes__holder__card__heading">
              <span>Level 3</span>6000₹+GST
            </p>

            <ul className="outcomes__holder__card__list outcomes__holder__card__list2">
              <li>Build your own website</li>
              <li>Advanced Concepts</li>
              <li>Completion of main project</li>
            </ul>
            <div className="divider"></div>
            <p className="outcomes__holder__card__heading outcomes__holder__card__heading2">
              One-time payment- 11000₹
            </p>
          </div>
        </Fade>
        <Fade bottom>
          <div className="outcomes__holder__card outcomes__holder__card2">
            <div className="outcomes__holder__card__top">
              <h5>Python</h5>
              <img
                src="/Images/LandingPage/python.png"
                alt="Brilliant bee features"
                className="outcomes__holder__card__top__img"
              />
            </div>
            <ul className="outcomes__holder__card__list ">
              <li>
                In this course the students will be introduced to the
                programming language ‘Python’ progressing from basic to advanced
                levels; based on which the students will be performing various
                activities and a main project at each level.
              </li>
            </ul>
            <p className="outcomes__holder__card__heading">
              <span>Level 1</span>3000₹+GST
            </p>
            <ul className="outcomes__holder__card__list outcomes__holder__card__list2">
              <li>Introduction to Python Programming</li>
              <li>Activities based on the topics</li>
              <li>Main project</li>
            </ul>
            <div className="divider"></div>
            <p className="outcomes__holder__card__heading">
              <span>Level 2</span>5000₹+GST
            </p>
            <ul className="outcomes__holder__card__list outcomes__holder__card__list2">
              <li>Intermediate Python Programming</li>
              <li>Activities based on the topics</li>
              <li>Main project </li>
            </ul>
            <div className="divider"></div>
            <p className="outcomes__holder__card__heading">
              <span>Level 3</span>6000₹+GST
            </p>
            <ul className="outcomes__holder__card__list outcomes__holder__card__list2">
              <li>Advanced Python Programming</li>
              <li>Activities based on the topics</li>
              <li>Main project</li>
            </ul>
            <div className="divider"></div>
            <p className="outcomes__holder__card__heading outcomes__holder__card__heading2">
              One-time payment- 11000₹
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default CoderbeeOutcomes;
