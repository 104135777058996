import "./header.scss";
import Fade from "react-reveal/Fade";
import React from "react";
import Jobcard from "./jobcard";

import { useState, useEffect } from "react";
import firebase from "../../../firebase/firebase";

import CircularProgress from "@material-ui/core/CircularProgress";
import InternCard from "./interncard"; ///////MaterialUI

import SuperContact from "../../universal-contact/super-contact";

//////////////
////////////////

const Careersection = () => {
  ///////////////////Firbase
  const [careerdataLoad, setcareerdataLoad] = useState(true);
  const ref = firebase.firestore().collection("career");
  let opportunity = [];
  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
        setcareerdataLoad(false);
      });
      setCareerdate(items);
    });
  }

  const [careerdata, setCareerdate] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (careerdata) {
      careerdata.forEach((item) => {
        opportunity.push(item.field);
      });
    }
  }, [careerdata]);
  ////////////////////////
  return (
    <>
      <div className="career-section">
        <div className="career-section__col1">
          <Fade bottom>
            <p className="career-section__head">WORK WITH US</p>
          </Fade>
          <Fade bottom>
            <p className="career-section__text">
              We provide you a unique experience, more opportunities to learn,
              to be able to directly voice your opinions, room for innovation
              and gain easier recognition for your work. We offer several
              internship positions in various fields such as sales and
              marketing, online teaching, design and content etc. <br />
              <br />
              Be part of our team, our BeylaBee family and this company that is
              our dream! Let us build together for the better !
            </p>
          </Fade>
        </div>
        <div className="career-section__col2">
          <InternCard />
        </div>
      </div>
      <div className="jobsection">
        <div className="jobsection__holder">
          <p className="career-section__head">OPPORTUNITIES</p>
          <p className="career-section__text">Opportunities from Beylabee</p>
          <div className="jobsection__jobs">
            {careerdataLoad ? (
              <div className="dataloadholder">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              careerdata.map((item, index) => {
                return <Jobcard key={index} data={item} />;
              })
            )}

            {!careerdataLoad & (careerdata.len === 0) ? (
              <div className="dataloadholder">No Recent Job Opportunities</div>
            ) : null}
          </div>
        </div>
      </div>

      {!careerdataLoad & (careerdata.len !== 0) ? (
        <>
          <div className="formbg">
            <SuperContact type="career" data={opportunity} key={868768} />
          </div>
        </>
      ) : null}
    </>
  );
};

export default Careersection;
//<imgsrc="/Images/LandingPage/business-3d.png"alt="Business Women"className="career-section__img"/>
