import firebase from "firebase/app";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDRVS_e-Jt8FjPmiH48rKyyyynbSchHsis",
  authDomain: "beyla-backend.firebaseapp.com",
  projectId: "beyla-backend",
  storageBucket: "beyla-backend.appspot.com",
  messagingSenderId: "964955453891",
  appId: "1:964955453891:web:56ecaf341370db7dba2576",
  measurementId: "G-TXZVZZRNN2",
};
firebase.initializeApp(firebaseConfig);
export default firebase;
