import Fade from "react-reveal/Fade";
import firebase from "firebase";
import { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useEffect } from "react";
import "./competition.scss";
////////////////////////////Dialod
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//////////////////////////
const CompCard = () => {
  const ref = firebase.firestore().collection("competition");
  const [compdata, setCompdata] = useState(false);
  const [loaddata, setLoaddata] = useState(true);
  function getData() {
    ref.onSnapshot((querysnapshot) => {
      const data = [];
      querysnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      setCompdata(data[0]);
      setLoaddata(false);
    });
  }
  useEffect(() => {
    getData();
    console.log(compdata);
  }, []);
  //////////////////////////

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  ////////////////
  return (
    <>
      <Fade bottom>
        <div className="glass competition-card">
          <img
            src="/Images/LandingPage/trophy.png"
            alt="trophy"
            className="competition-card__img"
          />
          <h4>BeeQuest</h4>
          <p className="competition-card__text">Weekly Trivia by Beylabee</p>
          <p className="competition-card__text classcards__holder__text2">
            <span> 9am to 9pm Sunday</span>
          </p>
          <div className="classcards-table">
            {loaddata ? (
              <Skeleton variant="rect" height={150} />
            ) : (
              <div className="classcards">
                <div className="classcards__holder">
                  <p className="classcards__holder__text">Classes </p>
                  <p className="classcards__holder__head">1-3</p>
                  <button
                    className="classcards__holder__btn"
                    onClick={() => {
                      if (!compdata.status) {
                        handleClickOpen();
                      } else {
                        window.open(compdata.link1, "_blank");
                      }
                    }}
                  >
                    START
                  </button>
                </div>
              </div>
            )}
            {loaddata ? (
              <Skeleton variant="rect" height={150} />
            ) : (
              <div className="classcards">
                <div className="classcards__holder">
                  <p className="classcards__holder__text">Classes </p>
                  <p className="classcards__holder__head">4-6</p>
                  <button
                    className="classcards__holder__btn"
                    onClick={() => {
                      if (!compdata.status) {
                        handleClickOpen();
                      } else {
                        window.open(compdata.link2, "_blank");
                      }
                    }}
                  >
                    START
                  </button>
                </div>
              </div>
            )}

            {loaddata ? (
              <Skeleton variant="rect" height={150} />
            ) : (
              <div className="classcards">
                <div className="classcards__holder">
                  <p className="classcards__holder__text">Classes </p>
                  <p className="classcards__holder__head">7-10</p>
                  <button
                    className="classcards__holder__btn"
                    onClick={() => {
                      if (compdata.status) {
                        window.location.replace(compdata.link3, "_blank");
                      } else {
                        handleClickOpen();
                      }
                    }}
                  >
                    START
                  </button>
                </div>
              </div>
            )}
          </div>
          <p className="competition-card__text classcards__holder__text2 ">
            Cash prize of ₹500 to our weekly winners
          </p>
          <p className="competition-card__text classcards__holder__text2 classcards__holder__text3">
            Few lucky winners can win upto
            <span> 1 Lakh </span>worth of scholarships on our courses
          </p>
        </div>
      </Fade>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="alert-head">
            {" "}
            Oops,Quiz is not available right now!
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className="alert-text">
              The quiz is open only on Sundays between 9am-9pm. <br></br>
              <span
                style={{
                  textAlign: "center",

                  fontWeight: "500",
                }}
              >
                Do come back then 😊💛
              </span>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <span className="alert-btn">OK</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompCard;
