import Navbar from "../Nav/nav";

import Footer from "../footer/footer";
import styled from "styled-components";

import Allcourses from "../AllCourses/allcourses";
import AllCourseslist from "../AllCourses/kitcontentoutcomes";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
const AllCoursePage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />

      <Allcourses />
      <Section>
        <AllCourseslist />
      </Section>

      <Footer />
    </>
  );
};

export default AllCoursePage;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
