import About from "../about/about";
import React, { useEffect } from "react";
import styled from "styled-components";
import Competition from "../competition/competition";
import Courses from "../courses/courses";
import Header from "../header/header";
import Kitcontent from "../kitcontents/kitcontent";
import Navbar from "../Nav/nav";
import Stem from "../stem/stem";
import Advantages from "../Advantages/advantages";
import ReactGA from "react-ga";
import Footer from "../footer/footer";
import SuperContact from "../universal-contact/super-contact";
//////////////////////////////
///////////Dialogue Imports

///////

const Home = () => {
  /////////////////Dialogu Functionalalities
  /*
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //useEffect(() => {
  // handleClickOpen();
  }, []);*/
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <Competition />
      <Courses />
      <Section>
        <Kitcontent />
        <Advantages />
        <Stem />

        <SuperContact type={null} />
      </Section>
      <Footer />
      {/*
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>*/}
    </>
  );
};

const Section = styled.div`
  background-image: url("/Images/LandingPage/img2-min.webp");
  background-size: cover;
`;
export default Home;
