export const content = {
  0: [
    [
      "Multiplication Master",
      "A Celestial Story",
      "Bee Wheels",
      "GreenBee",
      "Rapid Reactions",
      "Cool Convertebles",
      "Tints & Tones",
      "Infinity Calendar",
      "Simple Circuits",
    ],
    [
      "Build your own Multiplicative Machine to make multiplications more fun and interesting.",
      "Learn about our solar system and get to know our planet pals a lil better.",
      "The game is on! Build your very own racecar and compete.",
      "Get your own personalized environmental box to learn and contribute towards sustainibility.",
      "Curate your own Chemistry Lab with this amazing box full of experiments and fun activities.",
      "Wanna build an army? Build your army of robots with StemFormers.",
      "Predict the colour that will be formed or make new colours by mixing as you please.",
      "Tired of buying new calendars every year? Solve the issue by making your own infinity calendar.",
      "Get to know about various components of electric circuits and build your own circuits",
    ],
  ],
  1: [
    [
      "Kaleid-O-Scope",
      "FunFusion",
      "AeroActuator",
      "GreenBee",
      "CheckMate",
      "Marble Madness",
      "Peri-Periscope",
      "Mount Magma",
      "Infinity Calendar",
      "Circuit Saga",
    ],
    [
      "Build a kaleidoscope to visualize beautiful patterns and formations by implying concepts of physics.",
      "Play multiple games including exciting board games while learning about probability, gears and magnetism.",
      "Make your own air-powered car to understand the fundamentals of motion.",
      "Get your own personalized environmental box to learn and contribute towards sustainibility.",
      "Learn to be tactical while understanding the concepts of recoil and force.",
      "Understad elasticity and play while learning about projectile motion and angle deflection.",
      "Do you think you would make a good spy? Build a periscope to see hidden things without being seen.",
      "Build your own volcano to understand its concepts.",
      "Tired of buying new calendars every year? Solve the issue by making your own infinity calendar.",
      "Learn about the concepts of electric current circuits and parts of electric circuits.",
    ],
  ],
  2: [
    [
      "Magnifying the Minute",
      "Shuttle to Space",
      "Gears 'n' Gloves",
      "Flycraft",
      "Agent Angle",
      "Infinity Calendar",
      "GreenBEE",
      "LIKE likes LIKE",
      "Fussy Friction",
      "Going with Gravity",
    ],
    [
      "Explore different textures while learning about refraction and magnification. ",
      "Travel to space by building your own space rocket by learning how to apply rocket mechanics.",
      "Use transmitting forces from one point to another through a fluid to pick-up objects.",
      "Learn the concepts of Aerodynamics by trying to build a very cool project.",
      "Understand the concepts of angles to win the race.",
      "Tired of buying new calendars every year? Solve the issue by making your own infinity calendar.",
      "Get your own personalized environmental box to learn and contribute towards sustainibility.",
      "Use the power of magnetic poles to beat your opponent while learning about attraction and replusion.",
      "Understand the concepts of friction.",
      "Learn the laws and applications of gravity by building this transporter.",
    ],
  ],
  3: [
    [
      "I heart hydraulics",
      "Telescopic Sight",
      "Xylotunes",
      "GreenBee",
      "Infinity Calendar",
      "Tricky Targets",
      "Crash it with a Catapult",
      "Like a Chemical reaction",
      "Sketch it Smart",
      "Rest it with Resistors",
    ],
    [
      "Use the principles of hydraulics to launch paperplanes.",
      "Using your own telescope, observe, measure, and calculate the distance of distant objects while learning about refraction, depth of field, and trigonometric ratios.",
      "Know about resonance while playing music.",
      "Get your own personalized environmental box to learn and contribute towards sustainibility.Let's plant hope for our future, one seed at a time.",
      "The years can keep changing but your calendar doesnt need to. Let's make our own infinity calendars.",
      "Aim and shoot the targets while learning about transfer of momentum,weight,gravity and elasticity",
      `Understanding the use of stored energy to hurl a projectile.Understanding how tension, torsion, and gravity are used here.`,
      "Curate your own Chemistry Lab with this amazing box full of experiments and fun activities.",
      "Make science colourful and vibrant using this sketching machine.",
      "Learn about electric current, circuits and resistors while connecting your own little wires, bulbs and switches.",
    ],
  ],
  4: [
    [
      "Rolling it Retro",
      "GreenBee",
      "Infinity Calendar",
      "Hydraulic Crane",
      "Hydroelecricity power plant",
      "Like a Chemical reaction",
      "Telescope",
      "Ferromagnetic Money Magnet",
      "Weighing Machine",
      "Solar Six",
    ],
    [
      "Undertstand the concepts of gear by making your own movie.",
      "Get your own personalized environmental box to learn and contribute towards sustainibility.Let's plant hope for our future, one seed at a time.",
      "The years can keep changing but your calendar doesn't need to. Let's make your very own infinity calendars.",
      "Use transmitting forces from one point to another through a fluid to lift the loads and objects.",
      "Know about how tidal energy is converted to electrical energy.",
      "Curate your own Chemistry Lab with this amazing box full of experiments and fun activities.",
      "Get familiar with curved mirrors, lenses and various principles of light all the while having fun gazing into the distance.",
      "Play with the concepts of magnets and magnetism with this cool buildable.",
      "Now you can weigh, balance and measure with a weighing machine of your own creation.",
      "Understaning the potential of harnessing solar power and using it to fuel our daily activities.",
    ],
  ],
};
