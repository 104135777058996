const Kitclassitem = ({ head, sub, index }) => {
  return (
    <div className="kitclass__item">
      <div className="kitclass__item__head">{head}</div>
      <div className="kitclass__item__text">{sub}</div>
      <p className="kitclass__item__float">{index + 1}</p>
    </div>
  );
};

export default Kitclassitem;
