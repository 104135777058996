import "./header.scss";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";
import ReactPlayer from "react-player/youtube";
import { IoCloseOutline } from "react-icons/io5";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useState, useEffect } from "react";

import firebase from "../../firebase/firebase";
const Header = () => {
  const [videoplayer, setVideoplayer] = useState(false);
  const header = document.getElementsByClassName("popupvideo");

  const ref = firebase
    .firestore()
    .collection("analytics")
    .doc("vWmChsKXKgrZlsPEoTQb"); ////Firebase connection to Db
  // Atomically increment the population of the city by 50.

  function Analaytics() {
    if (!sessionStorage.getItem("session1")) {
      ref.update({
        uniqueVisit: firebase.firestore.FieldValue.increment(1),
      });
      sessionStorage.setItem("session1", true);
    } else {
      sessionStorage.setItem("session1", true);
    }
    if (!localStorage.getItem("session02")) {
      ref.update({
        totalvisit: firebase.firestore.FieldValue.increment(1),
      });
      localStorage.setItem("session02", true);
    } else {
      localStorage.setItem("session02", true);
    }
  }
  useEffect(() => {
    ///Analaytics();
  }, []);
  return (
    <>
      <header className="header">
        <div className="header__col1">
          <Fade left>
            <h1>
              <span className="h1-top">Bringing</span> Excellence To Your
              <span>Learning Aspirations.</span>
            </h1>
          </Fade>
          <Fade>
            <div className="header__col1__itemcontainer">
              <LazyLoadImage
                src="/Images/LandingPage/Breakpoint/img-1.png"
                alt="Bottle"
                className="header__col1__itemcontainer__img"
                effect="blur"
              />

              <p className="header__col1__itemcontainer__text">
                Hands-on education
              </p>
            </div>
          </Fade>
          <Fade>
            <div className="header__col1__itemcontainer">
              <LazyLoadImage
                src="/Images/LandingPage/Breakpoint/img.png"
                alt="Bottle"
                className="header__col1__itemcontainer__img"
                effect="blur"
              />
              <p className="header__col1__itemcontainer__text">
                Developing important life skills
              </p>
            </div>
          </Fade>
        </div>
        <div className="header__col2 flexbox-center ">
          <Fade bottom>
            <LazyLoadImage
              src="/Images/LandingPage/mockup.png"
              alt="Mockup"
              effect="blur"
              className="header__img"
            />
          </Fade>
          <Fade bottom delay={1000}>
            <button
              className="header-btn"
              onClick={() => {
                setVideoplayer(true);
              }}
            >
              <p>WATCH VIDEO</p>
            </button>
          </Fade>
        </div>
      </header>
      <div
        className={`popupvideo ${
          videoplayer ? "popupvideo-show" : "popupvideo-hide"
        }`}
      >
        <IoCloseOutline
          size="5em"
          className="popupvideo__icn"
          color="rgba(255, 255, 255, 0.96)"
          onClick={() => {
            setVideoplayer(false);
          }}
        />
        <div className="playerholder">
          <ReactPlayer
            url="https://youtu.be/ySIIqJRfXwI"
            className="videoplayer"
            controls
            width={"100%"}
            height={"100%"}
            playing={videoplayer}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
