import Fade from "react-reveal/Fade";
import "./scholarbee.scss";
import { Link } from "react-scroll";
const Scholarbeepage = () => {
  return (
    <div className="pageheader pageheader5">
      <div className="pageheader__col1">
        <Fade left>
          <h2 className="pageheader__head">SCHOLAR BEE</h2>
        </Fade>
        <Fade left delay={1000}>
          <p className="pageheader__text">
            This program is aimed at teaching students school-level Maths and
            Science in order to help them understand the concepts better and and
            know the importance of the subject in real life. We provide
            personalized one-on-one live classes to ensure proper understanding
            of the subjects, active doubt clarification and individual
            attention. Students will test their understanding via our weekly
            tests. Subjects will also be thoroughly revised before appearing for
            examinations.
          </p>
        </Fade>
        <Fade bottom delay={1000}>
          <Link to="contact">
            <button className="header-btn pageheader__btn">
              <p>BOOK FREE DEMO CLASS</p>
            </button>
          </Link>
        </Fade>
      </div>
      <div className="pageheader__col2">
        <Fade bottom delay={1500}>
          <div className="pageheader__card">
            <p className="pageheader__card__text">
              For Classes <span>6-12</span>
            </p>
            <div className="line"></div>

            <p className="pageheader__card__text pageheader__card__texts2">
              Classes Of
              <span>
                6<span className="pageheader__card__text2">hours/Week</span>
              </span>
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Scholarbeepage;
