import Navbar from "../Nav/nav";

import Footer from "../footer/footer";
import styled from "styled-components";

import SuperContact from "../universal-contact/super-contact";
import BibliobeeOutcomes from "../Bibliobee/bibliobeeoutcome";
import Bibliobeepage from "../Bibliobee/pageheader";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
const Bibliobee = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />

      <Bibliobeepage />
      <Section>
        <BibliobeeOutcomes />
        <SuperContact type={"Bibliobee"} />
      </Section>
      <Footer />
    </>
  );
};

export default Bibliobee;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
