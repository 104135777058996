import "./scholarbee.scss";
import Fade from "react-reveal/Fade";

const ScholarbeeOutcomes = () => {
  return (
    <>
      <div className="outcomes ">
        <h2 className="outcomes__head">COURSE INCLUDES</h2>
        <div className="outcomes__holder scholarbee">
          <Fade bottom>
            <div className="outcomes__holder__card">
              <div className="outcomes__holder__card__top outcomes__holder__card__top2">
                <h5>
                  Live <br />
                  Classes
                </h5>
                <img
                  src="/Images/LandingPage/brilliantBee/computer.png"
                  alt="Brilliant bee features"
                  className="outcomes__holder__card__top__img"
                />
              </div>
              <ul className="outcomes__holder__card__list">
                <li>One-on-One live classes</li>
                <li>Maths,Science live online home tuitions</li>
                <li>Active doubt clarification</li>
                <li>Weekly tests - Revision classes and exams</li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default ScholarbeeOutcomes;
