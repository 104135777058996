import Navbar from "../Nav/nav";

import Footer from "../footer/footer";
import styled from "styled-components";
import Linguistbeepage from "../Linguistbee/pageheader";
import LinguistbeeOutcomes from "../Linguistbee/liguistbeeoutcome";
import SuperContact from "../universal-contact/super-contact";
import ReactGA from "react-ga";
import React, { useEffect } from "react";
const Linguistbee = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navbar />
      <Linguistbeepage />
      <Section>
        <LinguistbeeOutcomes />
        <SuperContact type={"Liguistbee"} />
      </Section>
      <Footer />
    </>
  );
};

export default Linguistbee;
const Section = styled.div`
  background-image: url("/Images/LandingPage/img2.png");
`;
